import type { AuthMethod } from '@hubcms/domain-auth';
import type { GtmCode } from '@hubcms/domain-tracking';
import type { EPaperLinkImage } from '@hubcms/domain-navigation';

import ePaperImage from './e-paper.png';

export const gtmCodes: GtmCode[] = [{ code: 'GTM-5ZZP5H4' }, { code: 'GTM-W869F7D' }];
export const brandCode = 'hub';
export const footerLogo = 'logo-main-inverse';
export const authMethod: AuthMethod = 'none';
export const enableMySection = true;
export const ePaperLinkLabel = 'E-Paper';
export const ePaperLinkImage: EPaperLinkImage = {
  // Next.js and Storybook handle static image data differently. For Next.js, assign data.src. For Storybook, use fallback.
  src: ePaperImage.src ?? ePaperImage,
  height: 24,
  width: 32,
};
export const domain = 'hubgazette.com';
